<template>
  <ArticleList :type="NEWS_INFO.key" />
</template>

<script>
import ArticleList from "../../components/ArticleList.vue";
import { NEWS_INFO } from "@/constants/article";

export default {
  name: "articleList",
  components: {
    ArticleList,
  },
  data() {
    return {
      NEWS_INFO,
    };
  },
};
</script>
